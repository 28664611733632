/*********************************************************************************************************************
*  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
*                                                                                                                    *
*  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
*  with the License. A copy of the License is located at                                                             *
*                                                                                                                    *
*      http://aws.amazon.com/asl/                                                                                    *
*                                                                                                                    *
*  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
*  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
*  and limitations under the License.                                                                                *
**********************************************************************************************************************/

import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import CMS from 'netlify-cms-app';

import S3Backend from '../S3Backend';

/**
 * This component hosts the NetlifyCMS. It registers the S3 backend as well as setsup a callback on publish event from the NetlifyCMS.
 *  
 * @param {*} props 
 */
const CMSHost = props => {

    // Register the S3 backend and register for publish callback.
    useEffect(() => {
        Auth.currentCredentials().then(credentials => {
            try {
                CMS.registerBackend("s3", S3Backend);
            }
            catch (err) {
                console.log(err);
            }
            console.log("cp1")
            CMS.init({
                config: {
                    ...props.config,
                    load_config_file: false,
                    credentials,
                    onLogout: props.onClose
                }
            });
            console.log("cp2")
            CMS.registerEventListener({
                name: 'postPublish',
                handler: ({ author, entry }) => console.log('cms callback', JSON.stringify({ author, data: entry.get('data') })),
            });
            console.log("cp3")
        });
    }, [props.onClose, props.config]);

    // Return the JSX containing div with id 'nc-root' where NetlifyCMS will get attached.
    return (
        
            <div className={props.className} style={props.style}>
                <div id="nc-root" />
            </div>
        
    );
}

export default CMSHost;