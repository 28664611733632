/*********************************************************************************************************************
*  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
*                                                                                                                    *
*  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
*  with the License. A copy of the License is located at                                                             *
*                                                                                                                    *
*      http://aws.amazon.com/asl/                                                                                    *
*                                                                                                                    *
*  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
*  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
*  and limitations under the License.                                                                                *
**********************************************************************************************************************/

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import WarningIcon from '@material-ui/icons/Warning';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ReplayIcon from '@material-ui/icons/Replay';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import axios from 'axios';

// Build styles for various elements using material ui syntax.
const useStyles = makeStyles({
    root: {
        minWidth: 275,
        marginBottom: '5px',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    dialogContentParent: {
        position: 'relative',
    },
    dialogProgressParent: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    message: {
        textAlign: 'center',
        color: 'grey',
        marginBottom: '20px',
    },
    warningMessage: {
        textAlign: 'center',
        color: 'orange',
        marginBottom: '20px',
    },
    errorMessage: {
        textAlign: 'center',
        color: 'red',
        marginBottom: '20px',
    },
    contentParent: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    content: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column'
    },
    dateContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    pendingMessage: {
        color: 'red'
    },
    link: {
        alignSelf: 'left',
        textDecoration: 'none',
        color: 'black',
        marginRight: 'auto'
    }
});

/**
 * This component renders the details card for a single website. It displays all the information about the website such as
 * its domain name, description, as well as time time when it was initiates, verified, provisioned and deployed.
 * 
 * @param {*} props React props passed in from parent component.
 */
const SummaryCard = props => {
    const classes = useStyles();

    // States for this component.
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogPending, setDialogPending] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [deploying, setDeploying] = useState(false);
    const [infoVisible, setInfoVisible] = useState(false);

    // Utility function to handle click on the remove button.
    const handleRemove = () => {
        axios.delete('/websites', {
            data: {
                websiteid: props.site.websiteid
            }
        })
            .then(res => {
                setDialogOpen(false)
                props.onDone();
            })
            .catch(err => {
                console.log(err);
                setDialogOpen(false)
            });
    };

    // Utility function to handle click on the deploy button.
    const handleDeploy = () => {
        setDeploying(true);

        axios.post('/deployments', {
            data: {
                websiteid: props.site.websiteid
            }
        })
            .then(res => {
                handleReload();
            })
            .catch(err => {
                console.log('handleDeploy failed', err);
            });
    };

    // Utility function to handle click on the refresh button.
    const handleReload = () => {
        setRefreshing(true);

        axios.get('/websites', {
            params: {
                websiteid: props.site.websiteid
            }
        })
            .then(res => {
                setRefreshing(false);
                setDeploying(false);
                props.onReload(props.site, res.data.items.length > 0 ? res.data.items[0] : undefined);
            })
            .catch(err => {
                console.log('handleReload failed', err);
                setRefreshing(false);
                setDeploying(false);
            });
    };

    // Handler function for info button.
    const handleInfo = () => {
        setInfoVisible(true);
    };

    // Return JSX for the component.
    return (
        <Card className={classes.root} variant="outlined">
            <CardContent >
                <div className={classes.contentParent}>
                    <div className={classes.content}>
                        <a href={'https://' + props.site.domain} target="_blank" rel="noopener noreferrer" className={classes.link}>
                            <Typography variant="h5" component="h2">
                                {props.site.domain}
                            </Typography>
                        </a>
                        <Typography className={classes.pos} color="textSecondary">
                            {props.site.description}
                        </Typography>
                    </div>
                    <span>
                        {props.site.NSResourceRecords !== undefined ?
                            <IconButton color="primary" style={iconStyle} component="span" onClick={handleInfo} disabled={refreshing}>
                                <InfoOutlinedIcon />
                            </IconButton>
                            :
                            null
                        }
                        {props.site.status !== 'READY' && props.site.status !== 'ERROR' ?
                            <IconButton color="primary" component="span" onClick={handleReload} disabled={refreshing}>
                                <ReplayIcon />
                            </IconButton>
                            :
                            null
                        }
                    </span>
                </div>
                <div className={classes.dateContainer}>
                    <Typography variant="body2" component="p">
                        {`Initiated on ${new Date(props.site.initiateDate).toLocaleDateString()} at ${new Date(props.site.initiateDate).toLocaleTimeString()}`}
                    </Typography>
                    <Typography variant="body2" component="p">
                        {props.site.provisionedDate ?
                            `Provisioned on ${new Date(props.site.provisionedDate).toLocaleDateString()} at ${new Date(props.site.provisionedDate).toLocaleTimeString()}`
                            :
                            <span className={classes.pendingMessage}> Not provisioned yet !</span>
                        }
                    </Typography>
                </div>
                <div className={classes.dateContainer}>
                    <Typography variant="body2" component="p">
                        {props.site.verifiedDate ?
                            `Verified on ${new Date(props.site.verifiedDate).toLocaleDateString()} at ${new Date(props.site.verifiedDate).toLocaleTimeString()}`
                            :
                            <span className={classes.pendingMessage}> Not verified yet !</span>
                        }
                    </Typography>
                    <Typography variant="body2" component="p">
                        {props.site.deployedDate ?
                            `Last deployed on ${new Date(props.site.deployedDate).toLocaleDateString()} at ${new Date(props.site.deployedDate).toLocaleTimeString()}`
                            :
                            <span className={classes.pendingMessage}> Never deployed !</span>
                        }
                    </Typography>
                </div>
                <div className={classes.dateContainer}>
                    <Typography variant="body2" component="p">
                    <b>
                        {   props.site.NSResourceRecords ?
                            `NS Records`:``
                        }
                    </b>
                    </Typography>
                    
                            {props.site.NSResourceRecords ?
                                JSON.parse(props.site.NSResourceRecords).map(item => <Typography variant="body2" component="p">{item.Value}</Typography>): ``
                            }
                   
                    
                </div>
            </CardContent>

            {
                props.site.status === 'READY' ?
                    (
                        <CardActions style={{ justifyContent: 'center' }}>
                            <Button size="small" onClick={() => setDialogOpen(true)}>Remove</Button>
                            <Button size="small" onClick={props.onContentEdit}>Edit</Button>
                            <Button size="small" onClick={handleDeploy} disabled={deploying}>Deploy</Button>
                        </CardActions>
                    )
                    :
                    (refreshing ?
                        <Typography variant="body2" component="p" className={classes.message}>Refreshing... </Typography>
                        :
                        (props.site.status === 'ERROR' ?
                            <div>
                                <Typography variant="body2" component="p" className={classes.errorMessage} style={{ marginBottom: 0 }}>{'ERROR: ' + props.site.errorMessage} </Typography>
                                <CardActions style={{ justifyContent: 'center', paddingTop: 0 }}>
                                    <Button size="small" onClick={() => setDialogOpen(true)}>Remove</Button>
                                </CardActions>
                            </div>
                            :
                            <Typography variant="body2" component="p" className={classes.warningMessage}>{getMessage(props.site.status)}</Typography>
                        )
                    )
            }

            {
                dialogOpen ?
                    <Dialog open className={classes.dialogContentParent}>
                        <div style={{ visibility: dialogPending ? "hidden" : "visible" }}>
                            <DialogContent style={{ display: 'flex', alignItems: 'center' }} >
                                <WarningIcon color="error" style={{ width: '50px', height: '50px', marginRight: '20px' }} />
                                <DialogContentText>
                                    Removal of websites is non-reversible. All the content and settings for {props.site.domain} will be lost. Do you really want to remove this website now ?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setDialogOpen(false)} color="primary"> Cancel </Button>
                                <Button onClick={() => {
                                    setDialogPending(true);
                                    handleRemove();
                                }} color="primary" > Remove </Button>
                            </DialogActions>
                        </div>

                        <div className={classes.dialogProgressParent} style={{ visibility: dialogPending ? "visible" : "hidden" }}>
                            <CircularProgress style={{ marginRight: '30px' }} />
                            <Typography variant="body2">Removing website...</Typography>
                        </div>
                    </Dialog>
                    :
                    null
            }

            {
                infoVisible ?
                    <Dialog open className={classes.dialogContentParent}>
                        <DialogTitle >Website metadata</DialogTitle>
                        <DialogContent style={{ display: 'flex', alignItems: 'center' }} >
                            <DialogContentText>
                                <pre>
                                    {JSON.stringify({
                                        ...props.site,
                                        NSResourceRecords: JSON.parse(props.site.NSResourceRecords).map(item => item.Value)
                                    }, null, 2)}
                                </pre>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setInfoVisible(false)} color="primary"> CLOSE </Button>
                        </DialogActions>
                    </Dialog>
                    :
                    null
            }


        </Card >
    );
};

/**
 * Utility function to get the status message to display depending on the state of the website.
 * 
 * @param {*} status The status message to be used.
 */
const ulStyle = {
    listStyleType: "none"
  };
const iconStyle = {
    display: "none"
};
const getMessage = status => {
    switch (status) {
        case 'PREPARING_PENDING':
        case 'PENDING_WORKING':
        case 'PREPARING_WAITING':
        case 'PREPARING_CHECKING':
            return 'Website is being prepapred. This should be done in a minute.';
        case 'VERIFYING_WAITING':
        case 'VERIFYING_CHECKING':
            return 'Domain is being verified. This may take upto 30 minutes.';
        case 'VERIFYING_SUCCESS':
        case 'PROVISIONING_WORKING':
        case 'PROVISIONING_WAITING':
        case 'PROVISIONING_CHECKING':
            return 'Website is being provisioned. This will take about 15 minutes.';
        case 'DEPLOY_PENDING':
        case 'DEPLOY_WORKING':
        case 'DEPLOY_WAITING':
        case 'DEPLOY_CHECKING':
        case 'INVALIDATE_WAITING':
        case 'INVALIDATE_CHECKING':
            return 'Website is being deployed. This will take about 10 minutes.';
        case 'REMOVING_PENDING':
        case 'REMOVING_WORKING':
        case 'REMOVING_WAITING':
        case 'REMOVING_CHECKING':
            return 'Website is being removed. This will take about 15 minutes.';
        default:
            return 'Please wait...';
    }
}

export default SummaryCard;