"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  properties: {
    format: {
      type: 'string'
    },
    dateFormat: {
      oneOf: [{
        type: 'string'
      }, {
        type: 'boolean'
      }]
    },
    timeFormat: {
      oneOf: [{
        type: 'string'
      }, {
        type: 'boolean'
      }]
    },
    pickerUtc: {
      type: 'boolean'
    }
  }
};
exports.default = _default;