/*********************************************************************************************************************
*  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
*                                                                                                                    *
*  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
*  with the License. A copy of the License is located at                                                             *
*                                                                                                                    *
*      http://aws.amazon.com/asl/                                                                                    *
*                                                                                                                    *
*  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
*  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
*  and limitations under the License.                                                                                *
**********************************************************************************************************************/

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AWS from 'aws-sdk';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Build styles for various elements using material ui syntax.
const useStyles = makeStyles({
    dialog: {
        padding: '15px',
        width: '500px'
    },
    buttonHost: {
        display: 'flex',
        justifyContent: 'right',
        marginTop: '30px'
    },
    input: {
        width: '100%',
        marginTop: '15px',
    },
    dialogTitle: {
        width: '100%',
        marginBottom: '15px',
        textAlign: 'center'
    },
    dialogContentParent: {
        position: 'relative',
    },
    progressContainer: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

/**
 * This component renders the dialog where user creates a new website. It has fields to accept domain name, desription as well as
 * choose a template for the website.
 * 
 * @param {*} props React props passed in from parent component.
 */
const NewDialog = props => {
    const classes = useStyles();

    // States for the component.
    const [templates, setTemplates] = useState();
    const [domain, setDomain] = useState('xxx.cms.cloudevangelist.in');
    const [description, setDescription] = useState('');
    const [template, setTemplate] = useState(0);

    // List available templates.
    useEffect(() => {
        const s3 = new AWS.S3({
            apiVersion: "2006-03-01"
        });

        const params = {
            Bucket: props.awsconfig.aws_s3_bucket,
            Key: 'templates/manifest.json'
        };

        s3.getObject(params).promise()
            .then(data => {
                setTemplates(JSON.parse(data.Body.toString()).map(item => item.name));
            })
            .catch(err => {
                console.log("Error manifest", err);
            });
    }, [props.awsconfig.aws_s3_bucket]);

    // Utility function to handle new website creation.
    const addNewSite = params => {
        axios.post('/websites',
            {
                description: params.description,
                domain: params.domain,
                template: params.template
            })
            .then(res => {
                props.onDone()
            })
            .catch(err => {
                console.log(err);
                props.onDone()
            });
    };

    // Return JSX for the component.
    return (
        <Dialog open >
            <div className={classes.dialog}>
                <div className={classes.dialogContentParent}>
                    <div style={{ visibility: templates === undefined ? 'hidden' : 'visible' }}>
                        <Typography variant="h6" className={classes.dialogTitle}>
                            Create new website
                        </Typography>

                        <Typography>
                            Enter details for the new website and click on the create button.
                        </Typography>

                        <TextField className={classes.input} label="Domain name" value={domain} onChange={event => setDomain(event.target.value)} />
                        <TextField className={classes.input} label="Description" value={description} onChange={event => setDescription(event.target.value)} />

                        <FormControl className={classes.input}>
                            <InputLabel >Template</InputLabel>
                            <Select value={template} onChange={event => setTemplate(event.target.value)}>
                                {templates === undefined ? null : templates.map((item, index) => <MenuItem key={item} value={index}>{item}</MenuItem>)}
                            </Select>
                        </FormControl>

                        <div className={classes.buttonHost}>
                            <Button color="primary" onClick={props.onCancel}> Cancel </Button>
                            <Button color="primary" disabled={domain.length === 0 || description.length === 0} style={{ marginLeft: '10px' }} onClick={() => {
                                setTemplates(undefined);
                                addNewSite({
                                    domain,
                                    description,
                                    template: templates[template]
                                });
                            }}> Create </Button>
                        </div>
                    </div>

                    <div className={classes.progressContainer} style={{ visibility: templates !== undefined ? 'hidden' : 'visible' }}>
                        <CircularProgress style={{ marginRight: '30px' }} />
                        <Typography variant="body2">Creating website...</Typography>
                    </div>
                </div>

            </div>
        </Dialog>
    );
};

export default NewDialog;