"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _redux = require("redux");

var _connectedReactRouter = require("connected-react-router");

var _reduxNotifications = require("redux-notifications");

var _reduxOptimist = _interopRequireDefault(require("redux-optimist"));

var _index = _interopRequireDefault(require("./index"));

var _immutable = require("immutable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const combineReducersWithReset = reducers => {
  return (state, action) => {
    if (action.type === 'LOGOUT') {
      return {};
    } // console.log('skkv ...> ', state, action);


    const newState = {};
    Object.keys(reducers).forEach(key => {
      newState[key] = reducers[key](state === undefined ? undefined : state[key], action);
    }); // console.log('skkv ...< ', state, newState);

    return newState;
  };
};

const createRootReducer = history => {
  return (0, _reduxOptimist.default)(combineReducersWithReset(_objectSpread(_objectSpread({}, _index.default), {}, {
    notifs: _reduxNotifications.reducer,
    router: (0, _connectedReactRouter.connectRouter)(history)
  })));
};

var _default = createRootReducer;
exports.default = _default;