/*********************************************************************************************************************
*  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
*                                                                                                                    *
*  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
*  with the License. A copy of the License is located at                                                             *
*                                                                                                                    *
*      http://aws.amazon.com/asl/                                                                                    *
*                                                                                                                    *
*  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
*  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
*  and limitations under the License.                                                                                *
**********************************************************************************************************************/

import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
//import { withAuthenticator } from '@aws-amplify/ui-react';
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';
import  Amplify,  { Auth } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import awsconfig from './aws-exports';
import CMSHost from './cms/components/CMSHost';
import Dashboard from './portal/Dashboard';

// Configure AWS Amplify.
Amplify.configure(awsconfig);
Auth.configure(awsconfig);

// Build styles for various elements using material ui syntax.

// const withAuthenticatorConfig = {
//   signUpConfig: { hiddenDefaults: ['phone_number'], },
//   signUpAttributes={[
//          'name'
//         ]}
// };
const useStyles = makeStyles({
    loading: {
        display: 'flex',
        flex: '1 1 auto',
        justifyContent: 'center',
        alignItems: 'center'
    },
    root: {
        display: 'flex',
        flex: '1 1 auto',
        right: 0,
    },
    dashboard: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        padding: '20px',
        paddingTop: 0,
    },
    cms: {
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
});

function App() {
    const classes = useStyles();

    // States used by the component.
    const [credentials, setCredentials] = useState();
    const [config, setConfig] = useState();

    const getAuthData = () => {
        Auth.currentCredentials().then(credentials => {
            Auth.currentSession().then(session => {
                Auth.currentUserInfo().then(userInfo => {
                    // Save to state
                    setCredentials({
                        ...credentials,
                        userName: userInfo.attributes.email,
                    });

                    // Update axios.
                    axios.defaults.baseURL = awsconfig.aws_api_invoke_url;
                    axios.defaults.headers.common['Authorization'] = `Bearer ${session.getAccessToken().getJwtToken()}`

                    // Update sdk.
                    AWS.config = new AWS.Config({
                        accessKeyId: credentials.accessKeyId,
                        secretAccessKey: credentials.secretAccessKey,
                        sessionToken: credentials.sessionToken,
                        region: 'ap-south-1'
                    });
                });
            });
        });

        // Refresh token after 15 minutes.
        setTimeout(getAuthData, 15 * 60 * 1000);
    };

    // Retrive the credentials and setup axios as well as AWS sdk.
    useEffect(() => {
        // Get auth tokens.
        getAuthData();
    }, []);

    // Utility function to handle click on the edit button.
    const handleEditSite = site => {
        const s3 = new AWS.S3({
            apiVersion: "2006-03-01"
        });

        const params = {
            Bucket: awsconfig.aws_s3_bucket,
            Key: 'contents/' + site.userid + '/' + site.websiteid + '/netlifycms-config.json'
        };

        s3.getObject(params).promise()
            .then(data => {
                setConfig(JSON.parse(data.Body.toString()));
            })
            .catch(err => {
                console.log("Error config", err);
            });
    };

    // Return JSX for the component.
    return (
       
        credentials === undefined ?
            (
                
                <div className={classes.loading}>
                    Logging in...
                </div>
            )
            :
            (
                <div className={classes.root}>
                    <Dashboard awsconfig={awsconfig} className={classes.dashboard} style={{ visibility: config === undefined ? 'visible' : 'hidden' }} onEditSite={site => handleEditSite(site)} credentials={credentials} />
                    {config !== undefined ? <CMSHost className={classes.cms} config={config} onClose={() => setConfig(undefined)} /> : null}
                </div >
            )
      
    );
}

export default withAuthenticator(App);
