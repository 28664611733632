"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.branchFromContentKey = exports.contentKeyFromBranch = exports.parseContentKey = exports.generateContentKey = exports.statusToLabel = exports.labelToStatus = exports.isCMSLabel = exports.MERGE_COMMIT_MESSAGE = exports.DEFAULT_PR_BODY = exports.CMS_BRANCH_PREFIX = void 0;
const CMS_BRANCH_PREFIX = 'cms';
exports.CMS_BRANCH_PREFIX = CMS_BRANCH_PREFIX;
const DEFAULT_PR_BODY = 'Automatically generated by Netlify CMS';
exports.DEFAULT_PR_BODY = DEFAULT_PR_BODY;
const MERGE_COMMIT_MESSAGE = 'Automatically generated. Merged on Netlify CMS.';
exports.MERGE_COMMIT_MESSAGE = MERGE_COMMIT_MESSAGE;
const NETLIFY_CMS_LABEL_PREFIX = 'netlify-cms/';

const isCMSLabel = label => label.startsWith(NETLIFY_CMS_LABEL_PREFIX);

exports.isCMSLabel = isCMSLabel;

const labelToStatus = label => label.substr(NETLIFY_CMS_LABEL_PREFIX.length);

exports.labelToStatus = labelToStatus;

const statusToLabel = status => `${NETLIFY_CMS_LABEL_PREFIX}${status}`;

exports.statusToLabel = statusToLabel;

const generateContentKey = (collectionName, slug) => `${collectionName}/${slug}`;

exports.generateContentKey = generateContentKey;

const parseContentKey = contentKey => {
  const index = contentKey.indexOf('/');
  return {
    collection: contentKey.substr(0, index),
    slug: contentKey.substr(index + 1)
  };
};

exports.parseContentKey = parseContentKey;

const contentKeyFromBranch = branch => {
  return branch.substring(`${CMS_BRANCH_PREFIX}/`.length);
};

exports.contentKeyFromBranch = contentKeyFromBranch;

const branchFromContentKey = contentKey => {
  return `${CMS_BRANCH_PREFIX}/${contentKey}`;
};

exports.branchFromContentKey = branchFromContentKey;