/*********************************************************************************************************************
*  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
*                                                                                                                    *
*  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
*  with the License. A copy of the License is located at                                                             *
*                                                                                                                    *
*      http://aws.amazon.com/asl/                                                                                    *
*                                                                                                                    *
*  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
*  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
*  and limitations under the License.                                                                                *
**********************************************************************************************************************/

import React from 'react';

/**
 * This file is mandatory for NetlifyCMS backends. We dont use it becuase NetlifyCMS is integrated inside our own app
 * and authentication is managed outside on NetlifyCMS.
 */
export default class AuthenticationPage extends React.Component {
    // Pass though implementation.
    componentDidMount() {
        this.props.onLogin();
    }

    // Pass though implementation.
    render() {
        return <div></div>;
    }
}