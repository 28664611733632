/*********************************************************************************************************************
*  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
*                                                                                                                    *
*  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
*  with the License. A copy of the License is located at                                                             *
*                                                                                                                    *
*      http://aws.amazon.com/asl/                                                                                    *
*                                                                                                                    *
*  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
*  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
*  and limitations under the License.                                                                                *
**********************************************************************************************************************/

import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';
import AuthenticationPage from './AuthenticationPage';

/**
 * Backend implementation for NetlifyCMS which uses S3 as the storage mechanism.
 */
export default class S3Backend {

    // Save the config and options.
    constructor(config, options = {}) {
        S3Backend.config = config;
        S3Backend.options = options;
    }

    // The backend is not git.
    isGitBackend() {
        return false;
    }

    // Place holder implementation.
    status() {
        return Promise.resolve(
            {
                auth: {
                    status: true
                },
                api: {
                    status: true,
                    statusPage: ''
                }
            }
        );
    }

    authComponent() {
        return AuthenticationPage;
    }

    restoreUser(user) {
        return this.authenticate(user);
    }

    authenticate(credentials) {
        return new Promise((resolve, reject) => {
            this.credentials = credentials;

            AWS.config = new AWS.Config({
                accessKeyId: S3Backend.config.credentials.accessKeyId,
                secretAccessKey: S3Backend.config.credentials.secretAccessKey,
                sessionToken: S3Backend.config.credentials.sessionToken,
                region: 'ap-south-1'
            });
            resolve(credentials);
        });
    }

    logout() {
        this.credentials = null;
        S3Backend.config.onLogout();
    }

    getToken() {
        return Promise.resolve(this.credentials);
    }

    traverseCursor(cursor, action) {
        // Not used.
    }

    entriesByFolder(folder, extension, depth) {
        return new Promise((resolve, reject) => {
            const config = S3Backend.config.collections.find(collection => {
                return collection.folder === folder;
            });

            const regex = new RegExp(config.regex);
            const invertRegex = config.invert_regex;

            Auth.currentCredentials().then(credentials => {
                // Update sdk.
                AWS.config = new AWS.Config({
                    accessKeyId: credentials.accessKeyId,
                    secretAccessKey: credentials.secretAccessKey,
                    sessionToken: credentials.sessionToken,
                    region: 'ap-south-1'
                });

                const s3 = new AWS.S3({
                apiVersion: "2006-03-01",
                });

                const bucketParams = {
                    Bucket: S3Backend.config.backend.bucket,
                    Prefix: S3Backend.config.backend.basepath + '/' + folder + '/'
                };
                console.log("listObjectsV2",bucketParams);

                s3.listObjectsV2(bucketParams).promise()
                    .then(data => {
                        console.log("Success", data);

                        const filtered = data.Contents.filter(item => {
                            const path = item.Key.substring(item.Key.lastIndexOf(folder) + folder.length + 1);
                            return regex === undefined || (invertRegex ? !regex.test(path) : regex.test(path));
                        });

                        Promise.all(filtered.map(item => this.getEntry(item.Key.substring((S3Backend.config.backend.basepath + '/').length))))
                            .then(data => {
                                resolve(data);
                            })
                            .catch(err => {
                                reject(err);
                            });
                    }).catch(err => {
                        console.log("Error", err);
                    });
                    
                });

            
            });
    }

    entriesByFiles(files) {
        return new Promise((resolve, reject) => {
            Promise.all(files.map(file => ({
                file: file,
                data: this.getEntry(file.path).data
            })))
                .then(data => {
                    resolve(data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getEntry(path) {
        return new Promise((resolve, reject) => {
            Auth.currentCredentials().then(credentials => {
                // Update sdk.
                AWS.config = new AWS.Config({
                    accessKeyId: credentials.accessKeyId,
                    secretAccessKey: credentials.secretAccessKey,
                    sessionToken: credentials.sessionToken,
                    region: 'ap-south-1'
                });
                const s3 = new AWS.S3({
                    apiVersion: "2006-03-01"
                });
    
                const params = {
                    Bucket: S3Backend.config.backend.bucket,
                    Key: S3Backend.config.backend.basepath + '/' + path
                };
    
                console.log("getObject",params);
                s3.getObject(params).promise()
                    .then(data => {
                        console.log("Success x ", data, data.Body.toString());
                        resolve({
                            file: {
                                path: path,
                                id: path,
                            },
                            data: transformJSONForRead(path, data.Body.toString()),
                        });
                    }).catch(err => {
                        console.log("Error", err);
                        reject(err);
                    });
            });
            
        });
    }

    unpublishedEntries() {
        // Not used.
    }

    unpublishedEntry({ id, collection, slug }) {
        // Not used.
    }

    async unpublishedEntryDataFile(collection, slug, path) {
        // Not used.
    }

    async unpublishedEntryMediaFile(collection, slug, path) {
        // Not used.
    }

    deleteUnpublishedEntry(collection, slug) {
        // Not used.
    }

    async addOrUpdateUnpublishedEntry(key, path, newPath, raw, assetProxies, slug, collection, status) {
        // Not used.
    }

    async persistEntry(entry, assetProxies, options) {
        return new Promise((resolve, reject) => {

            Auth.currentCredentials().then(credentials => {
                // Update sdk.
                AWS.config = new AWS.Config({
                    accessKeyId: credentials.accessKeyId,
                    secretAccessKey: credentials.secretAccessKey,
                    sessionToken: credentials.sessionToken,
                    region: 'ap-south-1'
                });
                const s3 = new AWS.S3({
                    apiVersion: "2006-03-01"
                });
    
                const uploadParams = {
                    Bucket: S3Backend.config.backend.bucket,
                    Key: S3Backend.config.backend.basepath + '/' + entry.path,
                    Body: entry.raw
                };
                console.log("upload",uploadParams);
                s3.upload(uploadParams).promise()
                    .then(data => {
                        Promise.all(assetProxies.map(assetProxy => {
                            const uploadParams = {
                                Bucket: S3Backend.config.backend.bucket,
                                Key: S3Backend.config.backend.basepath + '/' + assetProxy.path,
                                Body: assetProxy.fileObj,
                            };
    
                            return s3.upload(uploadParams).promise();
                        }))
                            .then(() => {
                                resolve()
                            });
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
            
        });
    }

    updateUnpublishedEntryStatus(collection, slug, newStatus) {
        // Not used.
    }

    publishUnpublishedEntry(collection, slug) {
        // Not used.
    }

    getMedia(mediaFolder) {
        if (mediaFolder === undefined) {
            mediaFolder = S3Backend.config.media_folder;
        }

        return new Promise((resolve, reject) => {
            Auth.currentCredentials().then(credentials => {
                // Update sdk.
                AWS.config = new AWS.Config({
                    accessKeyId: credentials.accessKeyId,
                    secretAccessKey: credentials.secretAccessKey,
                    sessionToken: credentials.sessionToken,
                    region: 'ap-south-1'
                });
                
                const s3 = new AWS.S3({
                    apiVersion: "2006-03-01",
                });
    
                const bucketParams = {
                    Bucket: S3Backend.config.backend.bucket,
                    Prefix: S3Backend.config.backend.basepath + '/' + mediaFolder + '/'
                };
                
                console.log("listObjectsV2.2",bucketParams);
                s3.listObjectsV2(bucketParams).promise()
                    .then(data => {
                        console.log("getMedia Success", data);
    
                        resolve(Promise.all(data.Contents.map(item => {
                            console.log("getObject",item.key);
                            const url = s3.getSignedUrl('getObject', {
                                Bucket: S3Backend.config.backend.bucket,
                                Key: item.Key,
                            });
    
                            return {
                                id: item.Key,
                                name: item.Key.substring(item.Key.lastIndexOf('/') + 1),
                                size: item.Size,
                                displayURL: url,
                                url: url,
                                path: item.Key.substring((S3Backend.config.backend.basepath + '/').length),
                            }
                        })));
                    }).catch(err => {
                        console.log("getMedia Error", err);
                    });
                
            });
            
        });
    }

    async getMediaFile(path) {
        return new Promise((resolve, reject) => {
            Auth.currentCredentials().then(credentials => {
                // Update sdk.
                AWS.config = new AWS.Config({
                    accessKeyId: credentials.accessKeyId,
                    secretAccessKey: credentials.secretAccessKey,
                    sessionToken: credentials.sessionToken,
                    region: 'ap-south-1'
                });
                const s3 = new AWS.S3({
                    apiVersion: "2006-03-01"
                });
    
                const key = S3Backend.config.backend.basepath + '/' + path;
    
                const params = {
                    Bucket: S3Backend.config.backend.bucket,
                    Key: key,
                };
                
                console.log("getObject1",params);
                s3.getObject(params).promise()
                    .then(data => {
                        console.log("Success x ", data);
                        console.log("getObject2",key);
                        const url = s3.getSignedUrl('getObject', {
                            Bucket: S3Backend.config.backend.bucket,
                            Key: key,
                        });
    
                        const fileObj = new File(data.Body, key.substring(key.lastIndexOf('/') + 1), { type: data.ContentType });
    
                        resolve({
                            id: path,
                            displayURL: url,
                            path: path,
                            name: key.substring(key.lastIndexOf('/') + 1),
                            size: fileObj.size,
                            file: fileObj,
                            url,
                        });
                    }).catch(err => {
                        console.log("Error", err);
                        reject(err);
                    });
                
                
            });
            
        });
    }

    normalizeAsset(assetProxy) {
        // Not used.
    }

    persistMedia(mediaFile, options) {
        return new Promise((resolve, reject) => {
            Auth.currentCredentials().then(credentials => {
                // Update sdk.
                AWS.config = new AWS.Config({
                    accessKeyId: credentials.accessKeyId,
                    secretAccessKey: credentials.secretAccessKey,
                    sessionToken: credentials.sessionToken,
                    region: 'ap-south-1'
                });
                const s3 = new AWS.S3({
                    apiVersion: "2006-03-01"
                });
    
                const uploadParams = {
                    Bucket: S3Backend.config.backend.bucket,
                    Key: S3Backend.config.backend.basepath + '/' + mediaFile.path,
                    ContentType: mediaFile.fileObj.type,
                    Body: mediaFile.fileObj,
                };
                console.log("upload3",uploadParams);
                s3.upload(uploadParams).promise()
                    .then(data => {
                        console.log('persistMedia done');
                        console.log("getObject4",S3Backend.config.backend.basepath + '/' + mediaFile.path);
                        const url = s3.getSignedUrl('getObject', {
                            Bucket: S3Backend.config.backend.bucket,
                            Key: S3Backend.config.backend.basepath + '/' + mediaFile.path,
                        });
    
                        resolve({
                            id: mediaFile.path,
                            path: mediaFile.path,
                            displayURL: url,
                            url: url,
                            name: mediaFile.fileObj.name,
                            size: mediaFile.fileObj.size,
                        });
                    }).catch(err => {
                        console.log('persistMedia failed');
                        reject(err);
                    });
                
                
            });
            
        });
    }

    deleteFile(path) {
        return new Promise((resolve, reject) => {
            Auth.currentCredentials().then(credentials => {
                // Update sdk.
                AWS.config = new AWS.Config({
                    accessKeyId: credentials.accessKeyId,
                    secretAccessKey: credentials.secretAccessKey,
                    sessionToken: credentials.sessionToken,
                    region: 'ap-south-1'
                });
                const s3 = new AWS.S3({
                    apiVersion: "2006-03-01"
                });
    
                const params = {
                    Bucket: S3Backend.config.backend.bucket,
                    Key: S3Backend.config.backend.basepath + '/' + path
                };
                console.log("deleteObject",params);
                s3.deleteObject(params).promise()
                    .then(data => {
                        resolve(data);
                    })
                    .catch(err => {
                        reject(err)
                    });
            
            });
            
        });
    }

    async getDeployPreview() {
        return null;
    }
}

/**
 * Utility function to add top level object to json files that contain a list at the top level. This is a work around
 * for the issue in NetlifyCMS where top level arrays are not supported.
 * 
 * @param {*} path 
 * @param {*} data 
 */
const transformJSONForRead = (path, data) => {

    if (path.toLowerCase().endsWith('.json')) {
        const item = JSON.parse(data);
        if (Array.isArray(item)) {
            return `{"rootArray": ${data}}`
        }
    }

    return data;
}
