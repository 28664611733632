/*********************************************************************************************************************
*  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
*                                                                                                                    *
*  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
*  with the License. A copy of the License is located at                                                             *
*                                                                                                                    *
*      http://aws.amazon.com/asl/                                                                                    *
*                                                                                                                    *
*  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
*  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
*  and limitations under the License.                                                                                *
**********************************************************************************************************************/

// This objects holds various configuration data for the frontend. Most of them are updated based on the outputs from the CDK stack.
const awsconfig = {
    "aws_cognito_identity_pool_id": "ap-south-1:1c573acb-07b6-4aa7-bb39-fa011448d902",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_DWabLB5Cb",
    "aws_user_pools_web_client_id": "2nara6s798fv15je7ntrhcr7tt",
    "aws_s3_bucket": "cms-website-builder-bucket",
    "aws_api_invoke_url": "https://kypuf0wyzd.execute-api.ap-south-1.amazonaws.com/",
};

export default awsconfig;