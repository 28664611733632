/*********************************************************************************************************************
*  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
*                                                                                                                    *
*  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
*  with the License. A copy of the License is located at                                                             *
*                                                                                                                    *
*      http://aws.amazon.com/asl/                                                                                    *
*                                                                                                                    *
*  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
*  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
*  and limitations under the License.                                                                                *
**********************************************************************************************************************/

import React, { useEffect, useState, useRef } from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import axios from 'axios';

import logo from '../assets/logo.png';
import SummaryCard from './SummaryCard';
import NewDialog from './NewDialog';

// Refresh deplay.
const DELAY = 15000;

// Build styles for various elements using material ui syntax.
const useStyles = makeStyles({
    logo: {
        maxHeight: '70px',
        maxWidth: '500px',
        alignSelf: 'center',
        marginTop: '20px',
    },
    greetingContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '30px',
    },
    siteSuperContainer: {
        display: 'block',
    },
    siteActionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '700px',
        margin: 'auto'
    },
    siteContainer: {
        display: 'block',
        maxWidth: '800px',
        height: '100px',
        margin: 'auto',
    },
    firstSiteContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '700px',
        margin: 'auto',
        height: '200px',
    },
    loading: {
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center'
    }
});

// Handle logout event.
 async function handleLogout(){
    /*const currentUser = Auth.userPool.getCurrentUser();
    await currentUser.signOut()*/
    Auth.signOut();
    window.location.reload();
};

/**
 * This component renders the portal dashboard. It also hosts the logout button as well as takes care of auto refresh.
 * 
 * @param {*} props React props passed in from parent component.
 */
const Dashboard = props => {
    const classes = useStyles();
    console.log(props)
    // States for the component.
    const [sites, setSites] = useState();
    const [dialogOpen, setDialogOpen] = useState();

    // Reference object used to hold on to the time object.
    const timer = useRef(undefined);

    // Load sites.
    useEffect(() => {
        loadSites();
    }, []);

    // Check and install a timer to refresh the sites lost.
    useEffect(() => {
        // Remove previous timer.
        if (timer.current !== undefined) {
            clearInterval(timer.current);
        }

        // Do nothing if there are no sites.
        if (sites === undefined) {
            return;
        }

        // Check if all sites are in READY state.
        const allReady = sites.reduce((accum, curr) => {
            return accum && curr.status === 'READY';
        }, true);

        // If any site is not ready, schedule a refresh.
        if (!allReady) {
            timer.current = setInterval(() => {
                axios.get('/websites')
                    .then(res => {
                        setSites(res.data.items.sort((lhs, rhs) => {
                            return lhs.domain.localeCompare(rhs.domain);
                        }));
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }, DELAY);
        }
    }, [sites]);

    // Utility function to reload websites list.
    const loadSites = () => {
        setSites(undefined);
        axios.get('/websites')
            .then(res => {
                setSites(res.data.items.sort((lhs, rhs) => {
                    return lhs.domain.localeCompare(rhs.domain);
                }));
            })
            .catch(err => {
                console.log(err);
            });
    };

    // Utility function to handle reload requests on single website by clicking the reload button.
    const handleReload = (oldSite, newSite) => {
        let sitesNew = [...sites];

        if (newSite) {
            const index = sites.findIndex(item => item.websiteid === newSite.websiteid);
            sitesNew[index] = newSite;
        } else {
            const index = sites.findIndex(item => item.websiteid === oldSite.websiteid);
            if (index >= 0) {
                sitesNew.splice(index, 1);
            }
        }

        setSites(sitesNew);
    };

    // Return JSX for the dashboard component.
    return (
        <div className={props.className} style={props.style}>
            <img src={logo} alt="logo" className={classes.logo} />

            <div className={classes.greetingContainer}>
                <Typography variant="body1"> {`Welcome ${props.credentials.userName} !`} </Typography>
                <Button onClick={handleLogout}> Logout </Button>
            </div>

            <div className={classes.siteSuperContainer}>
                {sites === undefined ?
                    (
                        <div className={classes.loading}>
                            <Typography variant="body1" > Loading... </Typography>
                        </div>
                    )
                    :
                    (sites.length === 0 ?
                        (
                            <div className={classes.firstSiteContainer}>
                                <Typography variant="body1"> Create your first website: </Typography>
                                <Button onClick={() => setDialogOpen(true)}> Add new </Button>
                            </div>
                        )
                        :
                        (
                            <React.Fragment>
                                <div className={classes.siteActionContainer}>
                                    <Typography variant="body1"> Your websites </Typography>
                                    <Button onClick={() => setDialogOpen(true)}> Add new </Button>
                                </div>

                                <div className={classes.siteContainer}>
                                    {sites.map(site => (
                                        <SummaryCard
                                            key={site.websiteid}
                                            site={site}
                                            onReload={handleReload}
                                            onDone={loadSites}
                                            onContentEdit={() => props.onEditSite(site)}
                                        />
                                    ))}
                                </div>
                            </React.Fragment>
                        )
                    )}
            </div>

            {dialogOpen ? <NewDialog onCancel={() => setDialogOpen(false)} onDone={() => {
                loadSites();
                setDialogOpen(false);
            }} awsconfig={props.awsconfig} /> : null}
        </div>
    );
};

export default Dashboard;