"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.applyDefaults = applyDefaults;
exports.parseConfig = parseConfig;
exports.configLoaded = configLoaded;
exports.configLoading = configLoading;
exports.configFailed = configFailed;
exports.configDidLoad = configDidLoad;
exports.mergeConfig = mergeConfig;
exports.detectProxyServer = detectProxyServer;
exports.handleLocalBackend = handleLocalBackend;
exports.loadConfig = loadConfig;
exports.CONFIG_MERGE = exports.CONFIG_FAILURE = exports.CONFIG_SUCCESS = exports.CONFIG_REQUEST = void 0;

var _isPlainObject2 = _interopRequireDefault(require("lodash/isPlainObject"));

var _get2 = _interopRequireDefault(require("lodash/get"));

var _trim2 = _interopRequireDefault(require("lodash/trim"));

var _trimStart2 = _interopRequireDefault(require("lodash/trimStart"));

var _yaml = _interopRequireDefault(require("yaml"));

var _immutable = require("immutable");

var _auth = require("./auth");

var publishModes = _interopRequireWildcard(require("../constants/publishModes"));

var _configSchema = require("../constants/configSchema");

var _collections = require("../reducers/collections");

var _backend = require("../backend");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const CONFIG_REQUEST = 'CONFIG_REQUEST';
exports.CONFIG_REQUEST = CONFIG_REQUEST;
const CONFIG_SUCCESS = 'CONFIG_SUCCESS';
exports.CONFIG_SUCCESS = CONFIG_SUCCESS;
const CONFIG_FAILURE = 'CONFIG_FAILURE';
exports.CONFIG_FAILURE = CONFIG_FAILURE;
const CONFIG_MERGE = 'CONFIG_MERGE';
exports.CONFIG_MERGE = CONFIG_MERGE;

const getConfigUrl = () => {
  const validTypes = {
    'text/yaml': 'yaml',
    'application/x-yaml': 'yaml'
  };
  const configLinkEl = document.querySelector('link[rel="cms-config-url"]');
  const isValidLink = configLinkEl && validTypes[configLinkEl.type] && (0, _get2.default)(configLinkEl, 'href');

  if (isValidLink) {
    const link = (0, _get2.default)(configLinkEl, 'href');
    console.log(`Using config file path: "${link}"`);
    return link;
  }

  return 'config.yml';
};

const setDefaultPublicFolder = map => {
  if (map.has('media_folder') && !map.has('public_folder')) {
    map = map.set('public_folder', map.get('media_folder'));
  }

  return map;
};

const defaults = {
  publish_mode: publishModes.SIMPLE
};

function applyDefaults(config) {
  return (0, _immutable.Map)(defaults).mergeDeep(config).withMutations(map => {
    // Use `site_url` as default `display_url`.
    if (!map.get('display_url') && map.get('site_url')) {
      map.set('display_url', map.get('site_url'));
    } // Use media_folder as default public_folder.


    const defaultPublicFolder = `/${(0, _trimStart2.default)(map.get('media_folder'), '/')}`;

    if (!map.has('public_folder')) {
      map.set('public_folder', defaultPublicFolder);
    } // default values for the slug config


    if (!map.getIn(['slug', 'encoding'])) {
      map.setIn(['slug', 'encoding'], 'unicode');
    }

    if (!map.getIn(['slug', 'clean_accents'])) {
      map.setIn(['slug', 'clean_accents'], false);
    }

    if (!map.getIn(['slug', 'sanitize_replacement'])) {
      map.setIn(['slug', 'sanitize_replacement'], '-');
    } // Strip leading slash from collection folders and files


    map.set('collections', map.get('collections').map(collection => {
      if (!collection.has('publish')) {
        collection = collection.set('publish', true);
      }

      const folder = collection.get('folder');

      if (folder) {
        if (collection.has('path') && !collection.has('media_folder')) {
          // default value for media folder when using the path config
          collection = collection.set('media_folder', '');
        }

        collection = setDefaultPublicFolder(collection);
        collection = collection.set('fields', (0, _collections.traverseFields)(collection.get('fields'), setDefaultPublicFolder));
        collection = collection.set('folder', (0, _trim2.default)(folder, '/'));

        if (collection.has('meta')) {
          const fields = collection.get('fields');
          const metaFields = [];
          collection.get('meta').forEach((value, key) => {
            const field = value.withMutations(map => {
              map.set('name', key);
              map.set('meta', true);
              map.set('required', true);
            });
            metaFields.push(field);
          });
          collection = collection.set('fields', (0, _immutable.fromJS)([]).concat(metaFields, fields));
        } else {
          collection = collection.set('meta', (0, _immutable.Map)());
        }
      }

      const files = collection.get('files');

      if (files) {
        collection = collection.delete('nested');
        collection = collection.delete('meta');
        collection = collection.set('files', files.map(file => {
          file = file.set('file', (0, _trimStart2.default)(file.get('file'), '/'));
          file = setDefaultPublicFolder(file);
          file = file.set('fields', (0, _collections.traverseFields)(file.get('fields'), setDefaultPublicFolder));
          return file;
        }));
      }

      if (!collection.has('sortableFields')) {
        const backend = (0, _backend.resolveBackend)(config);
        const defaultSortable = (0, _collections.selectDefaultSortableFields)(collection, backend);
        collection = collection.set('sortableFields', (0, _immutable.fromJS)(defaultSortable));
      }

      if (!collection.has('view_filters')) {
        collection = collection.set('view_filters', (0, _immutable.fromJS)([]));
      } else {
        collection = collection.set('view_filters', collection.get('view_filters').map(v => v.set('id', `${v.get('field')}__${v.get('pattern')}`)));
      }

      return collection;
    }));
  });
}

function mergePreloadedConfig(preloadedConfig, loadedConfig) {
  const map = (0, _immutable.fromJS)(loadedConfig) || (0, _immutable.Map)();
  return preloadedConfig ? preloadedConfig.mergeDeep(map) : map;
}

function parseConfig(data) {
  const config = _yaml.default.parse(data, {
    maxAliasCount: -1,
    prettyErrors: true,
    merge: true
  });

  if (typeof CMS_ENV === 'string' && config[CMS_ENV]) {
    Object.keys(config[CMS_ENV]).forEach(key => {
      config[key] = config[CMS_ENV][key];
    });
  }

  return config;
}

async function getConfig(file, isPreloaded) {
  const response = await fetch(file, {
    credentials: 'same-origin'
  }).catch(err => err);

  if (response instanceof Error || response.status !== 200) {
    if (isPreloaded) return parseConfig('');
    throw new Error(`Failed to load config.yml (${response.status || response})`);
  }

  const contentType = response.headers.get('Content-Type') || 'Not-Found';
  const isYaml = contentType.indexOf('yaml') !== -1;

  if (!isYaml) {
    console.log(`Response for ${file} was not yaml. (Content-Type: ${contentType})`);
    if (isPreloaded) return parseConfig('');
  }

  return parseConfig(await response.text());
}

function configLoaded(config) {
  return {
    type: CONFIG_SUCCESS,
    payload: config
  };
}

function configLoading() {
  return {
    type: CONFIG_REQUEST
  };
}

function configFailed(err) {
  return {
    type: CONFIG_FAILURE,
    error: 'Error loading config',
    payload: err
  };
}

function configDidLoad(config) {
  return dispatch => {
    dispatch(configLoaded(config));
  };
}

function mergeConfig(config) {
  return {
    type: CONFIG_MERGE,
    payload: config
  };
}

async function detectProxyServer(localBackend) {
  const allowedHosts = ['localhost', '127.0.0.1', ...((localBackend === null || localBackend === void 0 ? void 0 : localBackend.allowed_hosts) || [])];

  if (allowedHosts.includes(location.hostname)) {
    let proxyUrl;
    const defaultUrl = 'http://localhost:8081/api/v1';

    if (localBackend === true) {
      proxyUrl = defaultUrl;
    } else if ((0, _isPlainObject2.default)(localBackend)) {
      proxyUrl = localBackend.url || defaultUrl.replace('localhost', location.hostname);
    }

    try {
      console.log(`Looking for Netlify CMS Proxy Server at '${proxyUrl}'`);
      const {
        repo,
        publish_modes,
        type
      } = await fetch(`${proxyUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          action: 'info'
        })
      }).then(res => res.json());

      if (typeof repo === 'string' && Array.isArray(publish_modes) && typeof type === 'string') {
        console.log(`Detected Netlify CMS Proxy Server at '${proxyUrl}' with repo: '${repo}'`);
        return {
          proxyUrl,
          publish_modes,
          type
        };
      }
    } catch {
      console.log(`Netlify CMS Proxy Server not detected at '${proxyUrl}'`);
    }
  }

  return {};
}

async function handleLocalBackend(mergedConfig) {
  if (mergedConfig.has('local_backend')) {
    const {
      proxyUrl,
      publish_modes,
      type
    } = await detectProxyServer(mergedConfig.toJS().local_backend);

    if (proxyUrl) {
      mergedConfig = mergePreloadedConfig(mergedConfig, {
        backend: {
          name: 'proxy',
          proxy_url: proxyUrl
        }
      });

      if (mergedConfig.has('publish_mode') && !publish_modes.includes(mergedConfig.get('publish_mode'))) {
        const newPublishMode = publish_modes[0];
        console.log(`'${mergedConfig.get('publish_mode')}' is not supported by '${type}' backend, switching to '${newPublishMode}'`);
        mergedConfig = mergePreloadedConfig(mergedConfig, {
          publish_mode: newPublishMode
        });
      }
    }
  }

  return mergedConfig;
}

function loadConfig() {
  if (window.CMS_CONFIG) {
    return configDidLoad((0, _immutable.fromJS)(window.CMS_CONFIG));
  }

  return async (dispatch, getState) => {
    dispatch(configLoading());

    try {
      const preloadedConfig = getState().config;
      const configUrl = getConfigUrl();
      const isPreloaded = preloadedConfig && preloadedConfig.size > 1;
      const loadedConfig = preloadedConfig && preloadedConfig.get('load_config_file') === false ? {} : await getConfig(configUrl, isPreloaded);
      /**
       * Merge any existing configuration so the result can be validated.
       */

      let mergedConfig = mergePreloadedConfig(preloadedConfig, loadedConfig);
      (0, _configSchema.validateConfig)(mergedConfig.toJS());
      mergedConfig = await handleLocalBackend(mergedConfig);
      const config = applyDefaults(mergedConfig);
      dispatch(configDidLoad(config));
      dispatch((0, _auth.authenticateUser)());
    } catch (err) {
      dispatch(configFailed(err));
      throw err;
    }
  };
}