import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// eslint-disable-next-line import/no-unresolved
//import '@aws-amplify/ui-react/styles.css';

window.CMS_MANUAL_INIT = true;

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
